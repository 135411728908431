<ng-container *ngIf="data && !deviatingBillingAdr && usedAddress && cicoService.folioAddressStep !== 'create'">
  <div *ngIf="newDesign && cicoService.folioAddressStep === 'folio'">
    <div class="folio-header" *ngIf="!isStaticAddress(); else addressReadOnly">
      <label *ngIf="usedAddress.valid()" [for]="'address_' + number + '_' + 0" class="address redesign" data-cy="address-block" [innerHtml]="usedAddress.folioAddressInfoEmpty() ? 'address.deviating_invoice_address' : usedAddress.htmlBlock(true)" translate></label>
      <span *ngIf="!usedAddress.valid()" class="error" (click)="editInvoiceAdr()">{{'address.valid_adr' | translate}}</span>
      <span class="edit main-text btn-outline" (click)="editInvoiceAdr()" data-cy="edit-address" translate>
        address.edit_address
      </span>
    </div>

    <ng-template #addressReadOnly>
      <div class="address" data-cy="address-block" [innerHtml]="usedAddress.isEmpty() ? 'address.valid_adr' : usedAddress.htmlBlock(true)" translate></div>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="(data && newDesign) || deviatingBillingAdr">
  <div *ngIf="cicoService.folioAddressStep === 'select'">
    <div class="invoices-container">
      <div class="heading-container">
        <button class="btn-filled" [disabled]="fieldsInactive || addresses.length >= maxAdresses" data-cy="new-address-button" (click)="addNewAdrs()" type="button" translate>misc.new</button>
        <h2 translate *ngIf="!withoutFolio">service.folios.invoice_address</h2>
      </div>

      <div class="addresses-list" *ngIf="!cicoService.hideAddressList">
        <ng-container *ngFor="let address of addresses | addressPipe; let i = index">
          <app-address-item [address]="address" [index]="i" [changeable]="!fieldsInactive && address.manual" [checked]="isAddressSelected(i)" (checkedChange)="addressChooser(i)" (editClick)="editAddress($event)" (deleteClick)="deleteAddress($event)"></app-address-item>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="((usedAddress?.manual && cicoService.folioAddressStep === 'create') || deviatingBillingAdr) && fields && !isStaticAddress()">
    <div id="address-form">
      <app-button-group *ngIf="fields.address_type?.active" id="address_type" ngDefaultControl name="address_type" [(ngModel)]="usedAddress.address_type" [description]="fields.address_type.name" [options]="[['service.folios.private_address', AddressType.personal], ['service.folios.company_address', AddressType.company]]" [tooltip]="fields.address_type.description" [required]="true" (ngModelChange)="updateAddressType()"></app-button-group>

      <app-basic-input *ngIf="fields.company?.active && usedAddress.address_type === AddressType.company" id="invoice_company" ngDefaultControl name="invoice_company" [(ngModel)]="usedAddress.company" [description]="fields.company.name" [tooltip]="fields.company.description" [placeholder]="fields.company.name" [errorMsg]="'validation.company'" [required]="usedAddress.address_type === AddressType.company" (ngModelChange)="updateAddress()" [submited]="submited"></app-basic-input>

      <div class="two-col-grid">
        <app-basic-input *ngIf="fields.first_name?.active" id="invoice_first_name" ngDefaultControl name="invoice_first_name" [(ngModel)]="usedAddress.first_name" [description]="fields.first_name.name" [tooltip]="fields.first_name.description" [placeholder]="fields.first_name.name" [required]="fields.first_name.required && usedAddress?.manual && usedAddress.address_type !== AddressType.company" [errorMsg]="'validation.first_name'" (ngModelChange)="updateAddress()" [submited]="submited"></app-basic-input>

        <app-basic-input *ngIf="fields.last_name?.active" id="invoice_last_name" ngDefaultControl name="invoice_last_name" [(ngModel)]="usedAddress.last_name" [description]="fields.last_name.name" [tooltip]="fields.last_name.description" [placeholder]="fields.last_name.name" [required]="fields.last_name.required && usedAddress?.manual && usedAddress.address_type !== AddressType.company" [errorMsg]="'validation.last_name'" (ngModelChange)="updateAddress()" [submited]="submited"></app-basic-input>
      </div>

      <app-basic-input *ngIf="fields.address?.active" id="address" ngDefaultControl name="invoice_address" [(ngModel)]="usedAddress.address" [description]="fields.address.name" [tooltip]="fields.address.description" [placeholder]="fields.address.name" [required]="fields.address.required && usedAddress?.manual" [errorMsg]="'validation.address'" (ngModelChange)="updateAddress()" [submited]="submited"></app-basic-input>
    </div>

    <div class="address-grid" [class.special]="states?.length">
      <app-basic-input *ngIf="fields.zipcode?.active" id="invoice_zipcode" ngDefaultControl name="invoice_zipcode" [(ngModel)]="usedAddress.zipcode" [description]="fields.zipcode.name" [tooltip]="fields.zipcode.description" [placeholder]="fields.zipcode.name" [required]="fields.zipcode.required && usedAddress?.manual" [errorMsg]="'validation.zip'" (ngModelChange)="updateAddress()" [submited]="submited"></app-basic-input>

      <app-basic-input *ngIf="fields.city?.active" id="invoice_city" ngDefaultControl name="invoice_city" [(ngModel)]="usedAddress.city" [description]="fields.city.name" [tooltip]="fields.city.description" [placeholder]="fields.city.name" [required]="fields.city.required && usedAddress?.manual" [errorMsg]="'validation.city'" (ngModelChange)="updateAddress()" [submited]="submited"></app-basic-input>

      <app-select *ngIf="states?.length" id="invoice_state" ngDefaultControl name="invoice_state" [(ngModel)]="usedAddress.state" [items]="states" [errorMsg]="'validation.state'" [required]="currentAddress?.manual" [description]="'address.state' | translate" [placeholder]="'address.state' | translate" [showIcons]="true" (ngModelChange)="updateAddress()" [submited]="submited"></app-select>

      <app-select *ngIf="data.countries?.length && fields.country?.active" id="invoice_country" ngDefaultControl name="invoice_country" [(ngModel)]="usedAddress.country" [items]="data.countries" [errorMsg]="'validation.country'" [tooltip]="fields.country.description" [required]="fields.country.required && usedAddress?.manual" [description]="fields.country.name" [placeholder]="fields.country.name" [showIcons]="true" [triggerKeyboard]="false" (ngModelChange)="updateAddress()" [submited]="submited"></app-select>
    </div>
  </div>
</ng-container>
