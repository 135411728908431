<div id="boxes-container">
  <app-icon-box *ngFor="let option of options" [ngModel]="isSelected(option)" [text]="option.text" [biggerSize]="true" [cutText]="false" [cy]="option.icon" (ngModelChange)="selectionChange(option)">
    <i *ngIf="option.icon === 'passport'" class="fa-solid fa-passport passport-icon" [class.selected]="isSelected(option)"></i>
    <svg *ngIf="option.icon === 'ausweisapp2'" class="primary-stroke" [class.selected]="isSelected(option)" viewBox="0 0 62 62" fill="none">
      <path d="M17.4546 41.9982C19.7513 44.8834 22.8882 46.9561 26.4116 47.9291V54.699C21.2031 53.6741 16.4655 50.919 12.9642 46.85C9.18367 42.4564 7.1 36.8273 7.1 31C7.1 25.1727 9.18367 19.5437 12.9642 15.15C16.4655 11.081 21.2031 8.32586 26.4116 7.30103V14.0709C22.8882 15.0439 19.7513 17.1166 17.4546 20.0019C14.9725 23.1202 13.6204 27.0008 13.6204 31C13.6204 34.9992 14.9725 38.8798 17.4546 41.9982ZM44.5454 20.0019C42.2487 17.1166 39.1118 15.0439 35.5884 14.0709V7.30103C40.7969 8.32586 45.5345 11.081 49.0358 15.15C52.8163 19.5437 54.9 25.1727 54.9 31C54.9 36.8273 52.8163 42.4564 49.0358 46.85C45.5345 50.919 40.7969 53.6741 35.5884 54.699V47.9291C39.1118 46.9561 42.2487 44.8834 44.5454 41.9982C47.0275 38.8798 48.3796 34.9992 48.3796 31C48.3796 27.0008 47.0275 23.1202 44.5454 20.0019Z" stroke="#208293" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1 5C1 2.79086 2.79086 1 5 1H57C59.2091 1 61 2.79086 61 5V57C61 59.2091 59.2091 61 57 61H5C2.79086 61 1 59.2091 1 57V5Z" stroke="#208293" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
      <svg *ngIf="option.icon === 'without-card'" [class.selected]="isSelected(option)" xmlns="http://www.w3.org/2000/svg" class="passport-icon" viewBox="0 0 59 61" fill="#ffffff">
      <path d="M42.1874 3.05555V27.4999H2.81249V3.05555H42.1874ZM44.9999 0H0V27.4999H44.9999V0Z" fill="#ffffff" />
      <path d="M9.99999 17.5H29.9999V20H9.99999V17.5ZM5 17.5H7.49999V20H5V17.5ZM27.4999 12.5H29.9999V15H27.4999V12.5ZM22.5 12.5H24.9999V15H22.5V12.5ZM17.5 12.5H20V15H17.5V12.5ZM12.5 12.5H15V15H12.5V12.5ZM7.49999 12.5H9.99999V15H7.49999V12.5ZM24.9999 7.5H27.4999V9.99999H24.9999V7.5ZM29.9999 7.5V9.99999H32.4999V15H34.9999V7.5H29.9999ZM20 7.5H22.5V9.99999H20V7.5ZM15 7.5H17.5V9.99999H15V7.5ZM9.99999 7.5H12.5V9.99999H9.99999V7.5ZM5 7.5H7.49999V9.99999H5V7.5Z" fill="#ffffff" />
      <path d="M31.5 38C29.6678 39.2334 27.752 44.573 27.752 47.4219C27.752 51.6954 33.581 60.0001 38.3866 60.0001C43.1923 60.0001 45.9793 60.0001 50.683 60.0001C55.3867 60.0001 58.2436 55.9471 58.2436 52.6954C58.2436 48.1164 58.2436 43.5374 58.2436 38.9585C58.2436 37.2145 56.805 35.8008 55.0302 35.8008H55.0204C53.2511 35.8008 51.8168 37.2103 51.8168 38.9489" fill="#ffffff" />
      <path d="M31.5 38C29.6678 39.2334 27.752 44.573 27.752 47.4219C27.752 51.6954 33.581 60.0001 38.3866 60.0001C43.1923 60.0001 45.9793 60.0001 50.683 60.0001C55.3867 60.0001 58.2436 55.9471 58.2436 52.6954C58.2436 48.1164 58.2436 43.5374 58.2436 38.9585C58.2436 37.2145 56.805 35.8008 55.0302 35.8008H55.0204C53.2511 35.8008 51.8168 37.2103 51.8168 38.9489" stroke="black" stroke-width="1.2" stroke-linecap="round" />
      <path d="M32.5293 44.6688V23.1551C32.5293 21.4126 33.9668 20 35.74 20C35.743 20 35.746 20 35.7489 20C37.5285 20.0049 38.9687 21.4239 38.9687 23.1726V39.5605" fill="#ffffff" />
      <path d="M32.5293 44.6688V23.1551C32.5293 21.4126 33.9668 20 35.74 20C35.743 20 35.746 20 35.7489 20C37.5285 20.0049 38.9687 21.4239 38.9687 23.1726V39.5605" stroke="black" stroke-width="1.2" stroke-linecap="round" />
      <path d="M38.9746 38.9392V34.7365C38.9746 32.9836 40.4208 31.5625 42.2046 31.5625C43.9885 31.5625 45.4345 32.9836 45.4345 34.7365V38.9392" fill="#ffffff" />
      <path d="M38.9746 38.9392V34.7365C38.9746 32.9836 40.4208 31.5625 42.2046 31.5625C43.9885 31.5625 45.4345 32.9836 45.4345 34.7365V38.9392" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M45.4199 38.6494V35.7944C45.4199 34.0505 46.8586 32.6367 48.6333 32.6367C50.4081 32.6367 51.8468 34.0505 51.8468 35.7944V38.9521" fill="#ffffff" />
      <path d="M45.4199 38.6494V35.7944C45.4199 34.0505 46.8586 32.6367 48.6333 32.6367C50.4081 32.6367 51.8468 34.0505 51.8468 35.7944V38.9521" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </app-icon-box>
</div>
