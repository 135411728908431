import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { ApiService } from 'api_service';
import { BusinessService } from 'services/business.service';
import { Globals } from 'base';

@Component({
  selector: 'app-basket-widget',
  templateUrl: './widget.component.html'
})

export class BasketWidgetComponent implements OnInit, OnDestroy {
  @Input() cart: string[];

  subscriptions: Subscription = new Subscription();
  business: Business;

  constructor(private api: ApiService,
              private businessService: BusinessService,
              public globals: Globals) {}

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
