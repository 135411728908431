<ng-container *ngIf="fieldsLoaded">
  <div *ngIf="isShowIdnowSection">
    <app-pms-ci-authentication class="animation-out" [data]="data" [guest]="guest" [submitted]="submited" (dismiss)="dismissed()" (done)="checkIdnow(true)"></app-pms-ci-authentication>
  </div>

  <div *ngIf="!isShowIdnowSection">
    <div id="reservation-form" class="animation-out" *ngIf="!showCamera">
      <div *ngIf="cicoService.subStepForPrimaryGuest == 'primary_guest' || cicoService.subStepForPrimaryGuest == 'other_guest'">
        <div class="general-grid">
          <div>
            <app-basic-input *ngIf="fields['first_name']" ngDefaultControl name="first_name{{ index }}" [(ngModel)]="guest.first_name" [description]="fields['first_name']?.name" [tooltip]="fields['first_name'].description" [required]="fields['first_name'].required" [errorMsg]="'validation.first_name'" [submited]="primaryFormSubmited" (change)="setAddressName()"></app-basic-input>

            <app-basic-input *ngIf="fields['last_name']" ngDefaultControl name="last_name{{ index }}" [(ngModel)]="guest.last_name" [description]="fields['last_name']?.name" [tooltip]="fields['last_name'].description" [required]="fields['last_name'].required" [errorMsg]="'validation.last_name'" [submited]="primaryFormSubmited" (change)="setAddressName()"></app-basic-input>
          </div>

          <div class="group2grid">
            <app-select *ngIf="fields['gender']" ngDefaultControl [name]="fields['gender'].name" [items]="genders" [(ngModel)]="guest.gender" [tooltip]="fields['gender'].description" [required]="fields['gender'].required" [description]="fields['gender'].name" [errorMsg]="'validation.required'" [placeholder]="'validation.selection' | translate" [submited]="primaryFormSubmited" [showIcons]="false" [triggerKeyboard]="false" (ngModelChange)="checkFields()"></app-select>

            <app-date-input *ngIf="fields['date_of_birth']" ngDefaultControl name="date_of_birth{{ index }}" [(date)]="guest.date_of_birth" [description]="fields['date_of_birth'].name" [tooltip]="fields['date_of_birth'].description" [required]="fields['date_of_birth'].required" [validation]="fields['date_of_birth'].validation" [errorMsg]="'validation.date_of_birth'" [submitted]="primaryFormSubmited" (dateChange)="checkFields()"></app-date-input>

            <app-form-select *ngIf="fields['nationality'] && data.countriesNat?.length && checkNationality" [model]="guest" [key]="'nationality'" name="nationality{{ index }}" [label]="fields['nationality'].name" [errorLabel]="'validation.nationality'" [options]="data.countriesNat" [showIcons]="true" [tooltip]="fields['nationality'].description" [required]="fields['nationality'].required" [submited]="primaryFormSubmited" [redesign]="true" (optionValue)="checkDocuments(); checkFields()"></app-form-select>

            <app-basic-input *ngIf="fields['place_of_birth']" ngDefaultControl name="place_of_birth{{ index }}" [(ngModel)]="guest.place_of_birth" [description]="fields['place_of_birth'].name" [tooltip]="fields['place_of_birth'].description" [required]="fields['place_of_birth'].required" [submited]="primaryFormSubmited"></app-basic-input>
          </div>

          <div class="group3grid">
            <app-basic-input *ngIf="fields['email']" ngDefaultControl name="email{{ index }}" [(ngModel)]="guest.email" [description]="fields['email'].name" [tooltip]="fields['email'].description" [required]="fields['email'].required" [errorMsg]="'validation.email_wrong_format'" validation="email" [submited]="primaryFormSubmited"></app-basic-input>

            <app-basic-input *ngIf="fields['email']?.setting('double_check')" ngDefaultControl name="confirm_email{{ index }}" #confirm_email_field="ngModel" [(ngModel)]="data.confirmEmail" [description]="'misc.confirm_email_address' | translate" [placeholder]="'misc.confirm_email_address' | translate" [required]="true" [errorMsg]="'validation.email_not_match'" validation="email" [otherEmail]="guest.email" (paste)="$event.preventDefault()" [submited]="primaryFormSubmited"></app-basic-input>

            <app-phone-input *ngIf="fields['phone'] && phoneCountry" ngDefaultControl name="phone{{ index }}" [description]="fields['phone'].name" [countries]="data?.countries" [(phoneNumber)]="guest.phone" [tooltip]="fields['phone'].description" [required]="fields['phone'].required" [validation]="fields['phone'].validation" [countryFromAdr]="guest.address?.country" [submitted]="primaryFormSubmited"></app-phone-input>
          </div>

          <app-basic-input *ngIf="fields['profession']" ngDefaultControl name="profession{{ index }}" [(ngModel)]="guest.profession" [required]="fields['profession'].required" [description]="fields['profession'].name" [tooltip]="fields['profession'].description" [submited]="primaryFormSubmited"></app-basic-input>
        </div>
      </div>
      <div *ngIf="cicoService.subStepForPrimaryGuest == 'private_address' || (cicoService.subStepForPrimaryGuest == 'other_guest' && cicoService.subStepForPrimaryGuest != 'primary_guest')">
        <ng-container *ngIf="fields['private_address']">
          <div *ngIf="!primaryGuest">
            <button class="btn-filled apply-address" (click)="applyAddress();" [class.use-address]="useSavedAddress" type="button" data-cy="apply-address">
              <svg viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_950_4045)">
                  <path d="M10.625 1.875C10.625 1.37772 10.8225 0.900805 11.1742 0.549175C11.5258 0.197544 12.0027 0 12.5 0L17.5 0C18.163 0 18.7989 0.263392 19.2678 0.732233C19.7366 1.20107 20 1.83696 20 2.5V17.5C20 18.163 19.7366 18.7989 19.2678 19.2678C18.7989 19.7366 18.163 20 17.5 20H2.5C1.83696 20 1.20107 19.7366 0.732233 19.2678C0.263392 18.7989 0 18.163 0 17.5V2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0L10 0C9.6075 0.5225 9.375 1.17125 9.375 1.875V11.6163L6.0675 8.3075C6.00939 8.24939 5.9404 8.20329 5.86448 8.17185C5.78856 8.1404 5.70718 8.12421 5.625 8.12421C5.54282 8.12421 5.46144 8.1404 5.38552 8.17185C5.3096 8.20329 5.24061 8.24939 5.1825 8.3075C5.12439 8.36561 5.07829 8.4346 5.04685 8.51052C5.0154 8.58644 4.99921 8.66782 4.99921 8.75C4.99921 8.83218 5.0154 8.91356 5.04685 8.98948C5.07829 9.0654 5.12439 9.13439 5.1825 9.1925L9.5575 13.5675C9.61556 13.6257 9.68453 13.6719 9.76046 13.7034C9.83639 13.7349 9.91779 13.7511 10 13.7511C10.0822 13.7511 10.1636 13.7349 10.2395 13.7034C10.3155 13.6719 10.3844 13.6257 10.4425 13.5675L14.8175 9.1925C14.9349 9.07514 15.0008 8.91597 15.0008 8.75C15.0008 8.58403 14.9349 8.42486 14.8175 8.3075C14.7001 8.19014 14.541 8.12421 14.375 8.12421C14.209 8.12421 14.0499 8.19014 13.9325 8.3075L10.625 11.6163V1.875Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_950_4045">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {{ 'address.apply' | translate }}
            </button>
          </div>
          <app-pms-private-address ngDefaultControl [data]="data" [model]="guest" [field]="fields['private_address']" [submited]="privateFormSubmited" (fieldsChanged)="checkFields()"></app-pms-private-address>

          <ng-container *ngIf="primaryGuest && deviatingBillingAdr">
            <app-checkbox ngDefaultControl name="invoice_address" [(ngModel)]="cicoService.deviatingBillingAddress" (ngModelChange)="deviatingAddrToggleChange()" [option]="'address.deviating_invoice_address' | translate" [submitted]="privateFormSubmited"></app-checkbox>
            <app-pms-invoice-address-form *ngIf="cicoService.deviatingBillingAddress" class="animation-out" [data]="data" [deviatingBillingAdr]="deviatingBillingAdr" [newDesign]="true" [staticAddress]="false" [withoutFolio]="true" [submited]="privateFormSubmited"></app-pms-invoice-address-form>
          </ng-container>
        </ng-container>


        <app-checkbox *ngIf="fields['marketing'] && primaryGuest" ngDefaultControl name="marketing" [(ngModel)]="data.incident.reservation.marketing" [option]="fields['marketing'].name" [required]="fields['marketing'].required" [submitted]="privateFormSubmited"></app-checkbox>

        <div class="parking-flexbox" *ngIf="primaryGuest">
          <app-button-group *ngIf="transportation && fields['car_licence']" ngDefaultControl name="transportation" [(ngModel)]="data.incident.transportation" [options]="[['booking_data.transportation.public', 'public'], ['booking_data.transportation.private', 'private']]" [description]="'booking_data.transportation.title' | translate" [submitted]="privateFormSubmited" [required]="true"></app-button-group>

          <app-basic-input *ngIf="fields['car_licence'] && (!transportation || data.incident.transportation === 'private')" ngDefaultControl name="car_licence" [(ngModel)]="data.incident.reservation.car_licence" [required]="fields['car_licence'].required" [description]="fields['car_licence'].name" [tooltip]="fields['car_licence'].description" [submited]="privateFormSubmited"></app-basic-input>
        </div>
      </div>
    </div>
    <div *ngIf="cicoService.subStepForPrimaryGuest == 'identification_guest' || (cicoService.subStepForPrimaryGuest == 'other_guest' && cicoService.subStepForPrimaryGuest != 'primary_guest')">
      <app-passport-form [guest]="guest" [guestIndex]="guestIndex" [submited]="identificationFormSubmited"></app-passport-form>
    </div>
  </div>
</ng-container>

