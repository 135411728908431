import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeHr from '@angular/common/locales/hr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeNb from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeSl from '@angular/common/locales/sl';
import localeZh from '@angular/common/locales/zh-Hans';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { Module } from 'models/module';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { StyleService } from 'services/style.service';

@Injectable({providedIn: 'root'})
export class LanguageService {
  reload = false;
  subscriptions: Subscription = new Subscription();
  languages: any[];
  language = { flag: {}, language: '', name: '' };

  mapping = {
    ar: {ng: localeAr, moment: 'en', adyen: 'en-US', date: 'ar-EG', name: 'العربية'},
    cn: {ng: localeZh, moment: 'zh-cn', adyen: 'zh-CN', date: 'zh-cn', name: '中文'},
    cs: {ng: localeCs, moment: 'cs', adyen: 'cs-CS', date: 'cs', name: 'Čeština'},
    da: {ng: localeDa, moment: 'da', adyen: 'da-DK', date: 'da', name: 'Dansk'},
    de: {ng: localeDe, moment: 'de', adyen: 'de-DE', date: 'de', name: 'Deutsch'},
    en: {ng: localeEn, moment: 'en', adyen: 'en-US', date: 'en-GB', name: 'English'},
    es: {ng: localeEs, moment: 'es', adyen: 'es-ES', date: 'es', name: 'Español'},
    fi: {ng: localeFi, moment: 'fi', adyen: 'fi-FI', date: 'fi', name: 'Suomi'},
    fr: {ng: localeFr, moment: 'fr', adyen: 'fr-FR', date: 'fr', name: 'Français'},
    hr: {ng: localeHr, moment: 'hr', adyen: 'hr-HR', date: 'hr', name: 'Hrvatski'},
    hu: {ng: localeHu, moment: 'hu', adyen: 'hu-HU', date: 'hu', name: 'Magyar'},
    it: {ng: localeIt, moment: 'it', adyen: 'it-IT', date: 'it', name: 'Italiano'},
    ja: {ng: localeJa, moment: 'ja', adyen: 'ja-JP', date: 'ja', name: '日本語'},
    no: {ng: localeNb, moment: 'nb', adyen: 'no-NO', date: 'nb', name: 'Norsk'},
    nl: {ng: localeNl, moment: 'nl', adyen: 'nl-NL', date: 'nl', name: 'Nederlands'},
    pl: {ng: localePl, moment: 'pl', adyen: 'pl-PL', date: 'pl', name: 'Polski'},
    pt: {ng: localePt, moment: 'pt', adyen: 'pt-PT', date: 'pt', name: 'Português'},
    sl: {ng: localeSl, moment: 'sl', adyen: 'sl-SI', date: 'sl', name: 'Slovenščina'},
    ru: {ng: localeRu, moment: 'ru', adyen: 'ru-RU', date: 'ru', name: 'Русский'}
  };

  constructor(private translate: TranslateService,
              private api: ApiService,
              private guestService: GuestService,
              private styleService: StyleService,
              private businessService: BusinessService,
              private router: Router,
              private globals: Globals) {}

  getLanguages() {
    return new Promise((resolve, _reject) => {
      if (this.languages?.length) {
        resolve(this.languages);
      } else {
        this.api.get('languages').subscribe((success: any) => {
          this.languages = success.languages.map(lang => {
            lang.name = this.mapping[lang.language]?.name;
            return lang;
          });
          resolve(this.languages);
        }, ()=> {});
      }
    });
  }

  setLanguage(lang, reload = false) {
    this.reload = reload;
    return new Promise<string>((resolve, reject) => {
      this.guestService.setLocale(lang).then((locale: string) => {
        moment.locale(this.mapping[locale]['moment']);
        this.setLocale(locale);
        this.businessService.setLanguageTo(locale);
        this.globals._module = new Module({});

        try {
          caches.delete('straiv-requests-' + this.globals.business?.code).then(success => {
            if (success) {
              this.globals.cacheContent();
            }
            this.navigate();
            resolve(locale);
          }).catch(() => {
            this.navigate();
            resolve(locale);
          });
        } catch (e) {
          this.navigate();
          resolve(locale);
        }
      }).catch(() => {
        reject();
      });
    });
  }

  setLocale(locale, flag = true) {
    registerLocaleData(this.mapping[locale]['ng'], locale);
    if (flag) {
      this.setFlag(locale);
    }
    this.translate.use(locale);
    this.translate.setDefaultLang('en');
    this.styleService.set_lang(locale);
  }

  setFlag(locale) {
    this.getLanguages().then(() => {
      const language = this.languages.find(lang => lang?.language === locale);
      this.language.language = language?.language === 'cn' ? 'ZN' : language?.language?.toUpperCase();
      this.language.flag = language?.media?.flag;
      this.language.name = language?.name;
    }, () => {});
  }

  checkLanguage() {
    if (!this.translate.currentLang && this.guestService.guest) {
      this.translate.use(this.guestService.guest.locale);
    }
  }

  navigate() {
    if (this.reload) {
      this.router.navigate(['/g/', this.globals.getCode()]);
    }
  }

  initLanguages() {
    this.translate.addLangs(Object.keys(this.mapping));
  }
}
