<div class="instructions" *ngIf="(!allVisible && authRequired != 'required') || (authRequired === 'required' && !selOption) || allVisible" translate>service.check_in.general.select_nationality</div>

<div *ngIf="(!allVisible && authRequired != 'required') || (authRequired === 'required' && !selOption)" class="boxes-container animation-out">
  <app-icon-box *ngFor="let option of top5Countries" [ngModel]="isSelected(option)" [text]="option?.name" [cutText]="true" [cy]="option.value" (ngModelChange)="selectionChange(option)">
    <img *ngIf="option.flag" class="country-icon" [src]="option.flag" [alt]="option?.name">
    <i *ngIf="!option.flag" class="fas fa-globe globe-icon"></i>
  </app-icon-box>
</div>

<ng-container *ngIf="allVisible">
  <app-search-input [placeholder]="'misc.searchenter' | translate" name="country-search" [(ngModel)]="searchValue" (ngModelChange)="searchValueChange($event)" (click)="scroll()"></app-search-input>
  <div class="boxes-container all-countries animation-out">
    <app-icon-box *ngFor="let option of allCountries" [ngModel]="isSelected(option)" [text]="option?.name" [cutText]="true" [cy]="option.value" (ngModelChange)="selectionChange(option)">
      <img *ngIf="option.flag" class="country-icon" [src]="option.flag" [alt]="option?.name">
    </app-icon-box>
  </div>
</ng-container>

