export enum LogType {
  log = 'Black',
  info = 'DodgerBlue',
  error = 'Red',
  debug = 'Orange'
}


export enum EventConstants {
  // PMS
  navigationChange = 'pms.navigationChange',
  afterSubmitForm = "pms.afterSubmitForm",
  moveToNextOrPreviousPage = "pms.moveToNextOrPreviousPage",
  resetFieldForm = "pms.resetFieldForm",
  folioProcessInitiated = "pms.folioProcessInitiated",
  toShowIdNowWhenUserClickBack = "pms.toShowIdNowWhenUserClickBack",
}
