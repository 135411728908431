<app-module-title [module]="basket_module"></app-module-title>

<div class="container">
  <div class="container-left" *ngIf="cart">

    <div class="menu basket" *ngIf="notEmpty; else emptyBasket">
      <div class="head">
        <div></div>
        <div translate>shopping_card.name</div>
        <div translate>shopping_card.price</div>
        <div></div>
      </div>
      <ng-container *ngFor="let item of items">
        <a [routerLink]="['/g', globals.getCode(), 'menu', item.entry.id, item.id]">
          <div>{{item.count}}x</div>
          <div class="two-lines">
            {{item.entry.name}}
            <small *ngIf="item.entry.description" [innerHtml]="item.entry.description | stripTags"></small>
          </div>
          <div>{{item.entry.price_hidden ? null : (item.price | currency: business.currency)}}</div>
          <div>
            <span><i class="fas fa-cog"></i></span>
            <span><i class="fas fa-trash"></i></span>
          </div>
        </a>
        <a class="addons" *ngIf="item.option">
          <div>{{item.count}}x</div>
          <div>{{item.option.name}}</div>
          <div>{{item.option_price | currency: business.currency}}</div>
          <div></div>
        </a>
        <ng-container *ngIf="item.active_extras?.length">
          <a class="addons" *ngFor="let extra of item.active_extras">
            <div><i class="fas fa-plus"></i></div>
            <div>{{extra.name}}</div>
            <div>{{extra.total_price | currency: business.currency}}</div>
            <div></div>
          </a>
        </ng-container>
      </ng-container>
      <a class="service" *ngIf="cart.extra_charge > 0">
        <div></div>
        <div translate>shopping_card.extra_charge</div>
        <div>{{cart.extra_charge | currency: business.currency}}</div>
        <div></div>
      </a>
    </div>

    <div class="basket-total" *ngIf="notEmpty && cart.show_total_price">
      <h3>{{cart.total_price | currency: business.currency}}</h3>
      <label translate>shopping_card.total_price_without_amount</label>
      <p translate><i class="fas fa-info-circle"></i>shopping_card.vat_service</p>
    </div>

    <form (ngSubmit)="onSubmit()" #serviceForm="ngForm" [hidden]="!notEmpty">
      <fieldset [disabled]="disabled">
        <div class="alert" [innerHtml]="error_text" *ngIf="error_text"></div>

        <div class="form-box suggestions" *ngIf="suggestions?.length">
          <label translate>shopping_card.suggestions</label>
          <div *ngFor="let sug of suggestions">
            <div>
              <a [routerLink]="['/g', globals.getCode(), 'menu', sug.id]" class="main-bg">
                <div>{{sug.name}}</div>
                <div>{{sug.price | currency: business.currency}}</div>
              </a>
            </div>
          </div>
        </div>

        <div class="seperator"></div>

        <div class="form-box" *ngIf="module">
          <label translate>shopping_card.add_more</label>
          <div class="button-group dropdown">
            <a class="button grey form" [routerLink]="['/g', globals.getCode(), 'menu']" [innerHtml]="module.name"></a>
            <a class="button grey form dropdown" (click)="toggleCategories()" *ngIf="categories"><i class="fas fa-caret-down"></i></a>
          </div>
          <div class="menu condensed" id="category-menu" *ngIf="categories">
            <a [routerLink]="['/g', globals.getCode(), 'menu_category', item.category.id]" *ngFor="let item of categories">
              <div>{{item.category.name}}</div>
              <div><i class="fas fa-angle-right icon-next"></i></div>
            </a>
          </div>
        </div>

        <div class="seperator"></div>

        <div class="form-box">
          <label translate>shopping_card.delivery</label>
          <div class="open-dropdown">
            <div class="radio-container" *ngFor="let option of ['direct_delivery', 'later_delivery']">
              <input class="custom-radio" type="radio" name="deliver_radio" [id]="option" [checked]="option === 'direct_delivery'" (click)="direct_delivery(option === 'direct_delivery')">
              <label [for]="option">{{'shopping_card.' + option | translate}}</label>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!is_direct">
          <div class="form-box">
            <label translate>misc.date</label>
            <app-datepicker [key]="'date'" [model]="service" [minDate]="globals.today()" [maxDate]="globals.today(7)" [startView]="'month'" [required]="!is_direct"></app-datepicker>
          </div>

          <div class="form-box">
            <label translate>misc.time</label>
            <input type="time" name="time" #time="ngModel" [(ngModel)]="service.time" [required]="!is_direct">
            <label translate class="error">validation.time</label>
          </div>
        </ng-container>

        <div class="form-box" *ngIf="payments?.length && pay_method_field">
          <app-form-select [model]="service" [name]="'pay_method'" [label]="pay_method_field.name" [text]="pay_method_field.description" [options]="payments" [required]="pay_method_field.required"></app-form-select>
        </div>

        <app-form-fields [form]="service" [module]="module" *ngIf="module?.fields?.length"></app-form-fields>

        <ng-container *ngIf="module">
          <app-form-place [service]="service" [room_validation]="module.room_validation" *ngIf="globals.needPlace()"></app-form-place>
        </ng-container>

        <div class="form-box">
          <label translate>misc.confirm_mail</label>
          <input type="email" name="email" #email="ngModel" [(ngModel)]="service.email" placeholder="{{ 'misc.mail' | translate }}" emailvalidator required>
          <label translate class="error">validation.email</label>
        </div>

        <div class="form-box" *ngIf="module?.field('phone')">
          <label [innerText]="module.field('phone').name"></label>
          <small [innerText]="module.field('phone').description" *ngIf="module.field('phone').description"></small>
          <app-form-phone-field [model]="service" [key]="'phone'" [field]="module.field('phone')"></app-form-phone-field>
        </div>

        <app-form-send-cancel [confirm]="confirm_name" [status]="globals.send_cancel_disabled"></app-form-send-cancel>
        <p translate>shopping_card.terms_conditions</p>
      </fieldset>
    </form>

    <ng-template #emptyBasket>
      <p translate>shopping_card.empty_basket</p>
    </ng-template>
  </div>
</div>
