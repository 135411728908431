<a [routerLink]="['/g', globals.getCode(), 'basket']" class="basket_mini" *ngIf="cart && cart['items']?.length">
  <h4 translate>shopping_card.title</h4>
  <ul class="b-mini-list">
    <li *ngFor="let item of cart['items']">{{item.count}}x {{item?.entry?.name}}</li>
  </ul>
  <div class="b-mini-total" *ngIf="cart.show_total_price">
    <h5>{{cart.total_price | currency: business.currency}}</h5>
    <p translate class="faded">shopping_card.total_price_without_amount</p>
  </div>
</a>
