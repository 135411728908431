<footer *ngIf="!showCamera && !cicoService.addressChange && !(cicoService.disableBack && cicoService.disableNext) && !cicoService.infoScreen">
  <div class="btn-grid">
    <button *ngIf="!cicoService.hideBack || cicoService.nationalityChoseWithBackBtn" class="btn-outline" [class.small-width]="!cicoService.hideNext" [disabled]="cicoService.disableBack" data-cy="back-button" (click)="onPreviousClick()" translate>misc.back</button>

    <button *ngIf="!showOverlay && !cicoService.hideNext"  class="btn-filled" [class.small-width]="!cicoService.hideNext" [disabled]="cicoService.disableNext" data-cy="next-button" (click)="onNextClick()">
      {{confirmName | translate : {number: cicoService.totalGrossService} }}
      <ng-container *ngIf="nextIcon.valueOf()" [ngSwitch]="nextIcon.valueOf()">
        <i *ngIf="nextIcon()" class="fa-solid fa-chevron-right fa-lg"></i> <i *ngSwitchCase="'refresh'" class="fa-solid fa-rotate fa-rotate-180 fa-lg"></i>
      </ng-container>
    </button>
  </div>
</footer>
